import { InlineStack, Text } from "@shopify/polaris";
import {
  AutomationMajor,
  CodeMajor,
  GaugeMajor,
  HeaderMajor,
  ImageAltMajor,
  ImagesMajor,
  LegalMajor,
  LinkMinor,
  NoteMajor,
  ProductsMajor,
} from "@shopify/polaris-icons";
import { t } from "i18next";
import LearnMore from "../../Components/Common/LearnMore";

export const getNavigationLinksOld = () => [
  // {
  //   label: t("mocks.navigation.Google Page Speed"),
  //   destination: "/speed-optimize",
  // },
  {
    label: t("mocks.navigation.Meta Tags"),
    destination: "/metatags",
  },
  {
    label: t("mocks.navigation.Schema"),
    destination: "/schema",
  },
  {
    label: t("mocks.navigation.Image Optimization"),
    destination: "/imageoptimization",
  },
  {
    label: t("mocks.navigation.Google Index Status"),
    destination: "/google-index-status",
  },
  {
    label: t("mocks.navigation.Broken Link"),
    destination: "/brokenlink",
  },
  {
    label: t("mocks.navigation.Price"),
    destination: "/pricing",
  },
  {
    label: t("mocks.navigation.Google Search Console"),
    destination: "/google-search-console",
  },
  {
    label: t("mocks.navigation.Breadcrumbs Design"),
    destination: "/breadcrumbs",
  },
  {
    label: t("mocks.navigation.Sitemap"),
    destination: "/sitemap",
  },
  {
    label: t("mocks.navigation.Test SEO"),
    destination: "/test-seo-performance",
  },
  {
    label: t("mocks.navigation.Settings"),
    destination: "/settings",
  },
  {
    label: t("mocks.navigation.Feedback"),
    destination: "/feedback",
  },
];

export const getNavigationLinks = () => [
  {
    label: t("mocks.navigation.SEO Booster"),
    destination: "/seo-booster",
  },
  {
    label: t("mocks.navigation.Speed Booster"),
    destination: "/speed-booster",
  },
  {
    label: t("mocks.navigation.Google Search Console"),
    destination: "/google-search-console",
  },
  // {
  //   label: t("mocks.navigation.Automations"),
  //   destination: "/automations",
  // },
  {
    label: t("mocks.navigation.Price"),
    destination: "/pricing",
  },
  {
    label: t("mocks.navigation.Feedback"),
    destination: "/feedback",
  },
  {
    label: t("mocks.navigation.Settings"),
    destination: "/settings",
  },
];

export const getEnhanceSpeedNavigation = () => [
  {
    label: t("mocks.navigation.Image optimization"),
    destination: "/imageoptimization",
    icon: ImagesMajor,
    color: "success",
    description: t(
      "speedbooster.Compress the size of your store images to make your pages lighter and load faster, while still preserving the image quality."
    ),
    earningId: "imageOptimization",
    showEarnBadge: true,
    earnText: "Try & Earn 10 Image Credits",
    creditId: "aiMetaTags",
  },
  {
    label: t("mocks.navigation.Instant Page"),
    destination: "/instant-page",
    icon: GaugeMajor,
    color: "info",
    description: t(
      "speedbooster.Make your webpage load faster and improve user experience by loading essential content first when users hover over links."
    ),
  },
];

export const getSeoBoosterNavigation = () => [
  {
    label: (
      <InlineStack align="center" gap={200}>
        <Text variant="headingSm"> {t("mocks.navigation.AI Meta Tag")}</Text>
        <LearnMore
          type="icon"
          data={[{ url: "https://support.webrexstudio.com/en/getting-started-with-meta-tags" }]}
        />
      </InlineStack>
    ),
    destination: "/metatags",
    icon: ProductsMajor,
    color: "success",
    description: t(
      "seobooster.Create bulk templates for meta title and meta description of your pages to save hours and hours of manual optimization work."
    ),
    earningId: "aiMetaTags",
    showEarnBadge: true,
    earnText: "Try & Earn 15 AI Credits",
    usp: true,
  },
  {
    label: <Text variant="headingSm">{t("mocks.navigation.AI Image Alt")}</Text>,
    destination: "/imagealt",
    icon: ImageAltMajor,
    color: "success",
    description: t(
      "seobooster.Generate alt text for all your resource images to streamline optimization efforts."
    ),
    earningId: "aiImageAltText",
    showEarnBadge: true,
    earnText: "Try & Earn 15 AI Credits",
    usp: true,
  },
  {
    label: (
      <InlineStack align="center" gap={200}>
        <Text variant="headingSm"> {t("mocks.navigation.Schema")}</Text>
        <LearnMore
          type="icon"
          data={[{ url: "https://support.webrexstudio.com/en/getting-started-with-schema" }]}
        />
      </InlineStack>
    ),
    destination: "/schema",
    icon: LegalMajor,
    color: "info",
    description: t(
      "seobooster.Adding structured data can enable search results that are more engaging to users and might encourage them to interact  more with your website, which are called rich results."
    ),
    earningId: "productSchema",
    showEarnBadge: true,
    earnText: "Try & Earn 5 AI Credits",
    creditId: "aiMetaTags",
    usp: true,
  },
  {
    label: (
      <InlineStack align="center" gap={200}>
        <Text variant="headingSm">{t("BlogPost.AI Blog")}</Text>
        <LearnMore
          type="icon"
          data={[
            {
              url: "https://support.webrexstudio.com/en/getting-started-with-schema/ai-blog-posts-with-seo-booster",
            },
          ]}
        />
      </InlineStack>
    ),
    destination: "/blog-post",
    icon: AutomationMajor,
    color: "caution",
    description: t(
      "seobooster.Automatically generate blog posts for your store and save countless hours of manual optimization work with SEO Booster."
    ),
  },
  {
    label: (
      <InlineStack align="center" gap={200}>
        <Text variant="headingSm">{t("mocks.navigation.Breadcrumb Design")}</Text>
        <LearnMore
          type="icon"
          data={[{ url: "https://support.webrexstudio.com/en/getting-started-with-breadcrumb-design" }]}
        />
      </InlineStack>
    ),
    destination: "/breadcrumbs",
    icon: HeaderMajor,
    color: "emphasis",
    description: t(
      "seobooster.Add breadcrumbs design on your store front for collections as well as product pages."
    ),
  },
  {
    label: (
      <InlineStack align="center" gap={200}>
        <Text variant="headingSm">{t("mocks.navigation.Broken Links")}</Text>
        <LearnMore
          type="icon"
          data={[{ url: "https://support.webrexstudio.com/en/getting-started-with-broken-links" }]}
        />
      </InlineStack>
    ),
    destination: "/brokenlink",
    name: "brokenLinks",
    icon: LinkMinor,
    color: "critical",
    description: t(
      "seobooster.Automatically 301 redirect all broken (404) links to the page of your choice to avoid interrupting user experience and create a better signal to search engines."
    ),
    earningId: "brokenLinks",
    showEarnBadge: true,
    earnText: "Try & Earn 5 AI Credits",
    creditId: "aiMetaTags",
    badgeShowOnStatus: "profileData?.featureStatus?.brokenLinks||false",
  },
  {
    label: <Text variant="headingSm">{t("mocks.navigation.Test SEO Performance")}</Text>,
    destination: "/test-seo-performance",
    icon: NoteMajor,
    color: "warning",
    description: t(
      "seobooster.Evaluate and refine SEO strategies for optimal website performance in search engine results."
    ),
  },
];

export const getGscNavigation = () => [
  {
    label: (
      <InlineStack align="center" gap={200}>
        <Text variant="headingSm">{t("mocks.navigation.Submit For Google Index")}</Text>
        <LearnMore
          type="icon"
          data={[
            {
              url: "https://support.webrexstudio.com/en/getting-started-with-google-search-console/easily-index-your-non-indexed-pages-on-google-with-just-one-click",
            },
          ]}
        />
      </InlineStack>
    ),
    destination: "/google-index",
    icon: AutomationMajor,
    tabindex: 0,
    color: "magic",
    usp: true,
    description:
      "Automatically index your product and collection pages using our Google Index feature. Save time and boost visibility effortlessly",
  },
  {
    label: <Text variant="headingSm">{t("mocks.navigation.Google Search Report")}</Text>,
    destination: "/google-search-report",
    icon: LegalMajor,
    tabindex: 0,
    color: "emphasis",
    description: t(
      "seobooster.The Google Search Report provides crucial metrics like clicks, CTR, impressions and position details, offering valuable insights for online businesses."
    ),
  },
  {
    label: <Text variant="headingSm">{t("mocks.navigation.XML Sitemap")}</Text>,
    destination: "/sitemap",
    icon: NoteMajor,
    tabindex: 0,
    color: "warning",
    description: t(
      "seobooster.Enhance search engine indexing with our XML Sitemap feature, ensuring efficient discovery and visibility of your website's content."
    ),
  },
  {
    label: <Text variant="headingSm">{t("mocks.navigation.Html Sitemap")}</Text>,
    destination: "/sitemap",
    icon: CodeMajor,
    tabindex: 1,
    color: "info",
    description: t(
      "seobooster.Effortlessly navigate your site's structure with our concise Sitemap feature for optimal user and search engine accessibility."
    ),
  },
];
