import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { t } from "i18next";
import { useAuthenticatedFetch } from "@/Api/Axios";
import { ToastContext } from "@/Context/ToastContext";
import { logger } from "@/Services/Logger/Index";
import { getSessionStorageItem, isAdmin, setSessionStorageItem } from "../Utils/Index";

export const ProfileContext = createContext();
export const ProfileContextProvider = ({ children }) => {
  const [profileData, setProfileData] = useState(undefined);
  const [appStatus, setAppStatus] = useState(undefined);
  const [isProfileLoadding, setIsProfileLoadding] = useState(false);
  const fetch = useAuthenticatedFetch();
  const { showToast } = useContext(ToastContext);
  const fetchProfileData = async () => {
    try {
      setIsProfileLoadding(true);
      const res = await fetch.get("profile");
      if (res?.data) {
        logger.identifyUser({
          id: res?.data._id,
          email: res?.data.email || "email",
          shopUrl: res?.shopUrl,
        });
        logger.identifyUserScope(res?.data);
      }
      setIsProfileLoadding(false);
      setProfileData(res?.data || undefined);
      getAppBlockStatus(res?.data);
      const sessionFound = getSessionStorageItem("wsLastLogin");
      if (!sessionFound && !isAdmin()) {
        let lastLoginArray = res?.data?.lastLoginArray || [];
        lastLoginArray?.push({ date: Date.now() });
        if (lastLoginArray?.length > 10) {
          lastLoginArray = lastLoginArray?.slice(lastLoginArray?.length - 10, lastLoginArray?.length);
        }
        await fetch.put("/user/update", JSON.stringify({ lastLoginArray }));
        setSessionStorageItem("wsLastLogin", Date.now());
      }
    } catch (err) {
      setIsProfileLoadding(false);
      const authErr = err?.response?.status === 403 && err?.response?.data?.message === "Don't Refresh";
      if (!authErr) {
        setProfileData({ error: true });
      }
    }
  };

  const getAppBlockStatus = useCallback(async (profileData) => {
    const response = await fetch.get("app-block-status");
    if (response?.data?.webrexSeoEmbed) {
      setAppStatus({
        ...(profileData?.privateMetafield && JSON.parse(profileData?.privateMetafield?.value)),
        webrexSeoEmbed: true,
      });
    } else {
      setAppStatus(response.data);
    }
  }, []);

  useEffect(() => {
    if (appStatus?.webrexSeoEmbed) {
      setAppStatus({
        ...(profileData?.privateMetafield && JSON.parse(profileData?.privateMetafield?.value)),
        webrexSeoEmbed: true,
      });
    }
  }, [profileData]);

  useEffect(() => {
    fetchProfileData();
  }, []);

  const updateProfileData = (e) => {
    setProfileData(e);
  };

  const updateFeatureStatus = async (e) => {
    const response = await fetch.post("/user/featureStatus", e);
  };

  const updateUserEarningPoints = useCallback(
    async (earnPoint) => {
      const { earningId, earnCredit, creditId } = earnPoint;
      const postEarning = creditId && earningId && profileData && !profileData?.earningPoints?.[earningId];

      if (postEarning) {
        const response = await fetch.put("/user/earningPoints", earnPoint);
        if (response?.data?.credits) setProfileData(response?.data);
        setTimeout(() => {
          showToast(`${t(`earnCredit.You have Earn`)} ${earnCredit} ${t(`earnCredit.credit sucessfully`)}`);
        }, 2000); // Delay set to 5000 milliseconds (5 seconds)
      }
    },
    [profileData, setProfileData]
  );

  return (
    <ProfileContext.Provider
      value={{
        profileData,
        isProfileLoadding,
        updateProfileData,
        fetchProfileData,
        getAppBlockStatus,
        appStatus,
        updateFeatureStatus,
        updateUserEarningPoints,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};
