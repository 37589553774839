import React, { useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BlockStack, Navigation } from "@shopify/polaris";
import { DeleteMajor, DynamicSourceMajor, LogOutMinor, ProfileMajor } from "@shopify/polaris-icons";
import { removeLocalStorageItem } from "@/Utils/Index";

function AdminNavigationMenu() {
  const navigate = useNavigate();

  const location = useLocation();
  const [selected, setSelected] = useState(location.pathname);

  const handleLogout = () => {
    removeLocalStorageItem("adminPanelAccessToken");
    navigate("/admin/login");
  };
  const menu = [
    {
      label: "7 Day Premium",
      icon: DynamicSourceMajor,
      destination: "/admin/premiumTrial",
    },
    {
      label: "Recent Trial Cancel",
      icon: DynamicSourceMajor,
      destination: "/admin/trialCancel",
    },
    {
      label: "Premium Uninstallation",
      icon: DynamicSourceMajor,
      destination: "/admin/premiumUninstallation",
    },
    {
      label: "Plan Renewal",
      icon: DynamicSourceMajor,
      destination: "/admin/getPremiumRenewalUser",
    },
    {
      label: "User",
      icon: ProfileMajor,
      destination: "/admin/user",
    },
    {
      label: "DeleteUser",
      icon: DeleteMajor,
      destination: "/admin/deleteuser",
    },
    {
      label: "GetData",
      icon: DynamicSourceMajor,
      destination: "/admin/getdata",
    },
  ];

  const handleClick = useCallback((item) => {
    navigate(item.destination);
    setSelected(item.destination);
  }, []);

  return (
    <Navigation location={location.pathname}>
      <BlockStack>
        <Navigation.Section
          items={menu.map((item) => ({
            ...item,
            onClick: () => handleClick(item),
            selected: selected === item.destination,
          }))}
        />
        <Navigation.Section
          title="Others"
          items={[
            {
              label: "Logout",
              icon: LogOutMinor,
              onClick: handleLogout,
            },
          ]}
          rollup={{
            after: 0,
            view: "view",
            hide: "hide",
            activePath: "#",
          }}
        />
      </BlockStack>
    </Navigation>
  );
}

export default AdminNavigationMenu;
