import { ImagesMajor, LinkMinor, ProductsMajor, SearchMinor } from "@shopify/polaris-icons";
import { t } from "i18next";

export const getStaticSuggestedItems = () => [
  {
    icon: ImagesMajor,
    color: "success",
    title: t(`suggest.Optimize Images`),
    description: t(`suggest.Improve website performance by optimizing images.`),
    navigate: "/imageoptimization",
    tabindex: 2,
  },
  {
    icon: ProductsMajor,
    color: "info",
    title: t(`suggest.Generate Meta tags`),
    description: t(`suggest.Enhance SEO with automatically generated meta tags.`),
    navigate: "/metatags",
    tabindex: 1,
  },
];

export const getDynamicSuggestedItems = () => [
  {
    id: "meta_ai",
    icon: ProductsMajor,
    color: "info",
    title: t(`suggest.AI Meta Tag`),
    description: t(`suggest.Improve SEO with AI meta tag generation.`),
    navigate: "/metatags?tabindex=1",
  },
  {
    id: "meta_ai_bulkoperation",
    icon: ProductsMajor,
    color: "info",
    title: t(`suggest.Bulk Ai operation`),
    description: t(`suggest.Optimize SEO by automating bulk meta tag tasks.`),
    navigate: "/metatags?tabindex=5",
  },
  {
    id: "meta_ai_alttag",
    icon: ProductsMajor,
    color: "info",
    title: t(`suggest.Try Ai Alt tag`),
    description: t(`suggest.Enhance SEO by automating alt tag generation.`),
    navigate: "/metatags?tabindex=1",
  },
  {
    id: "meta_automation",
    icon: ProductsMajor,
    color: "info",
    title: t(`suggest.Setup meta tag automation`),
    description: t(`suggest.Automated meta tag management.`),
    navigate: "/metatags?tabindex=6",
  },
  {
    id: "image_automation",
    icon: ImagesMajor,
    color: "success",
    title: t(`suggest.Setup Automated Image Optimization`),
    description: t(`suggest.Automate image optimization processes.`),
    navigate: "/imageoptimization?tabindex=4",
  },
  {
    id: "image_bulkoperation",
    icon: ImagesMajor,
    color: "success",
    title: t(`suggest.Streamline Bulk Image Optimization`),
    description: t(`suggest.Optimize images with automated bulk processing.`),
    navigate: "/imageoptimization?tabindex=4",
  },
  {
    id: "schema_product",
    icon: ImagesMajor,
    color: "success",
    title: t(`suggest.Enable Product Schema`),
    description: t(`suggest.Rich product presentation by product schema.`),
    navigate: "/schema?tabindex=0",
  },
  {
    id: "gsc_connect",
    icon: SearchMinor,
    color: "warning",
    title: t(`suggest.Connect to Google Search Console`),
    description: t(`suggest.Improve visibility by connecting to Google Search Console.`),
    navigate: "/google-search-console",
  },
  {
    id: "gsc_xml_sitemap",
    icon: SearchMinor,
    color: "warning",
    title: t(`suggest.Generate XML Sitemap`),
    description: t(`suggest.Generate XML sitemap for enhanced search visibility.`),
    navigate: "/sitemap?tabindex=0",
  },
  // {
  //   id: "google_page_speed",
  //   icon: LinkMinor,
  //   color: "warning",
  //   title: t(`suggest.Enable Google Page Speed`),
  //   description: t(`suggest.Improve website speed with Google Page Speed.`),
  //   navigate: "/speed-optimize",
  // },
  {
    id: "brokenlink_automation",
    icon: LinkMinor,
    color: "warning",
    title: t(`suggest.Automate Broken Links`),
    description: t(`suggest.Detect and handle broken links automatically for better UX.`),
    navigate: "/brokenlink?tabindex=4",
  },
  {
    id: "breadcrumb_design",
    icon: LinkMinor,
    color: "warning",
    title: t(`suggest.Add Breadcrumbs`),
    description: t(`suggest.Optimize website speed with improved breadcrumb design.`),
    navigate: "/breadcrumbs?tabindex=0",
  },
  {
    id: "html_sitemap",
    icon: LinkMinor,
    color: "warning",
    title: t(`suggest.Manage HTML sitemap`),
    description: t(`suggest.Optimize website speed with HTML sitemap improvements.`),
    navigate: "/sitemap?tabindex=1",
  },
];
