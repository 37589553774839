import React, { useEffect } from "react";
import { Divider, InlineStack, Text } from "@shopify/polaris";

const handleTab = (paramName, action) => {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const searchedParamValue = urlParams.get(paramName);
    if (searchedParamValue) {
      setTimeout(() => {
        action(Number(searchedParamValue) || 0);
      }, 300);
    } else {
      action(0);
    }
  }, []);
};

const handleBackPageUrl = (paramName, action) => {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const searchedParamValue = urlParams.get(paramName);
    if (searchedParamValue) {
      setTimeout(() => {
        action(searchedParamValue === "home" ? "" : searchedParamValue || "");
      }, 300);
    }
    // else {
    //   action("");
    // }
  }, []);
};

const ViewCommonField = ({ user = {} }) => {
  const values = [];
  for (const key in user) {
    const value = user[key];
    if (Array.isArray(value) && value !== null) {
      values.push(
        <div key={key}>
          <InlineStack gap="200">
            <Text variant="headingMd">{key}:</Text>
            {value.map((item, index) => (
              <div key={`${key}_${index}`}>
                <ViewCommonField user={item} />
                <br />
              </div>
            ))}
          </InlineStack>
          <Divider />
        </div>
      );
    } else if (typeof value === "object" && value !== null) {
      values.push(
        <div key={key}>
          <InlineStack gap="200">
            <Text variant="headingMd">{key}:</Text>
            <ViewCommonField user={value} />
          </InlineStack>
          <Divider />
        </div>
      );
    } else {
      values.push(
        <div key={key}>
          <InlineStack gap="200">
            <Text variant="headingMd">{key} :</Text>
            <Text>{typeof value === "boolean" ? (value ? "true" : "false") : value}</Text>
          </InlineStack>
          <Divider />
        </div>
      );
    }
  }

  return values;
};

const makeCommonFormField = (user = {}, excludeFields = []) => {
  const fieldArray = [];
  for (const key in user) {
    if (!excludeFields.includes(key)) {
      if (Array.isArray(user[key]) && user[key] !== null) {
        if (typeof user[key][0] === "string") {
          let subfields = makeCommonFormField(user[key][0]);
          fieldArray.push({
            id: key,
            name: key,
            label: key,
            type: "array",
            hideAddbtn: false,
            hideDeletebtn: false,
            groupSize: subfields.length,
            section: true,
            subfields: subfields,
          });
        } else {
          let subfields = makeCommonFormField(user[key][0]);
          fieldArray.push({
            id: key,
            name: key,
            label: key,
            nested: "array",
            hideAddbtn: false,
            hideDeletebtn: false,
            groupSize: subfields.length,
            section: true,
            subfields: subfields,
          });
        }
      } else if (typeof user[key] === "object" && user[key] !== null) {
        let subfields = makeCommonFormField(user[key]);
        fieldArray.push({
          id: key,
          name: key,
          label: key,
          nested: "object",
          groupSize: subfields.length,
          section: true,
          subfields: subfields,
        });
      } else {
        fieldArray.push({
          id: key,
          name: key,
          label: key,
          type: "text",
        });
      }
    }
  }
  return fieldArray;
};

const findOperatorLabel = (value) => {
  switch (value) {
    case "$gt":
      return ">";
    case "$gte":
      return ">=";
    case "$lt":
      return "<";
    case "$lte":
      return "<=";
    case "$eq":
      return "=";
    default:
      return value;
  }
};

const booleanLabel = (val) => (typeof val === "boolean" ? (val ? "Yes" : "No") : val);

const makeAdvanceFilterLabel = (value, label) => {
  let labelWithValue = ``;
  if (Array.isArray(value)) {
    const firstElementType = typeof value[0];
    if (firstElementType === "object") {
      const keyValue = value.map((obj) => {
        const keyValuePairs = Object.entries(obj).map(([key, value]) => `${key}:${JSON.stringify(value)}`);
        return keyValuePairs.join(",");
      });
      labelWithValue = `${label}: ${keyValue}`;
    } else {
      labelWithValue = `${label}: ${value?.map((e) => booleanLabel(e))?.join(", ")}`;
    }
  } else if (typeof value === "object") {
    const resultString = Object.entries(value)
      .map(([key, value]) => {
        if (key === "operators") return findOperatorLabel(value);
        else return `${value}`;
      })
      .join(" ");
    labelWithValue = `${label}: ${resultString}`;
  } else {
    labelWithValue = `${label}: ${booleanLabel(value)}`;
  }
  return labelWithValue;
};

const capitalName = (name) => name.charAt(0).toUpperCase() + name.slice(1);

export { handleTab, handleBackPageUrl, makeCommonFormField, ViewCommonField, makeAdvanceFilterLabel, capitalName };
